<template>
  <v-dialog v-model="isVisible" max-width="400">
    <v-card>
      <v-card-title
        class="tw-font-bebas-neue tw-text-uppercase tw-text-charcoal"
        >Delete Event?</v-card-title
      >
      <v-card-text class="tw-text-base"
        >Are you sure you want to continue?</v-card-text
      >
      <v-card-actions class="tw-justify-end">
        <button class="btn-tertiary tw-mx-4" @click="$emit('cancel')">
          Cancel
        </button>
        <v-btn
          rounded
          color="primary"
          class="tw-px-4 tw-tracking-normal"
          @click="deleteEvent"
          >Delete</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { EVENT_TYPES } from "@/enums/event-types.js";
export default {
  props: {
    isVisible: Boolean,
    event: Object
  },
  methods: {
    async deleteEvent() {
      if (this.event.eventType === EVENT_TYPES.ITINERARY_EVENT) {
        await this.$store.dispatch(
          "itinerary-event/remove",
          this.event.preservedId
        );
      } else {
        await this.$store.dispatch("activity/remove", this.event.preservedId);
      }

      this.$store.dispatch("meta/showGlobalAlert", {
        type: "success",
        text: "Event deleted successfully",
        timeout: 2000
      });
      this.$emit("close");
    }
  }
};
</script>
