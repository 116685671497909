<template>
  <div class="tw-relative itinerary-list" v-if="dataLoaded">
    <div class="floating-overlay" v-if="showEventsMenu">
      <div
        class="tw-absolute tw-flex tw-flex-col tw-items-end tw-bottom-10 tw-right-10"
      >
        <button
          class="tw-flex tw-items-center tw-justify-end tw-mb-6"
          type="button"
          @click="openSubmenuItem('ActivityToItineraryRoute', null)"
        >
          <span class="tw-mr-4 tw-text-sm tw-font-semibold"
            >Fun things to do</span
          >
          <img
            class="tw-w-10 tw-h-10"
            src="@/assets/svgs/itinerary/ADVENTURE.svg"
            alt="Fun things to do"
          />
        </button>
        <button
          class="tw-flex tw-items-center tw-justify-end tw-mb-6"
          type="button"
          @click="openSubmenuItem('TripItineraryCreate', events.TRAVEL)"
        >
          <span class="tw-mr-4 tw-text-sm tw-font-semibold">Travel</span>
          <img
            class="tw-w-10 tw-h-10"
            src="@/assets/svgs/itinerary/TRAVEL.svg"
            alt="Travel Details"
          />
        </button>
        <button
          class="tw-flex tw-items-center tw-justify-end tw-mb-12"
          type="button"
          @click="openSubmenuItem('TripItineraryCreate', events.ERRANDS)"
        >
          <span class="tw-mr-4 tw-text-sm tw-font-semibold"
            >Errand or Other</span
          >
          <img
            class="tw-w-10 tw-h-10"
            src="@/assets/svgs/itinerary/ERRAND.svg"
            alt="ERRAND or OTHER"
          />
        </button>
        <button
          class="tw-bg-white tw-shadow-custom tw-rounded-full tw-w-16 tw-h-16"
          type="button"
          @click="deactivateEventsMenu()"
        >
          <v-icon color="#203848">mdi-close</v-icon>
        </button>
      </div>
    </div>
    <div class="floating-wrapper" v-if="hasDestination && hasDate">
      <v-btn dark fab color="#E6FFA1" @click="activateEventsMenu()">
        <v-icon color="#203848">mdi-plus</v-icon>
      </v-btn>
    </div>
    <div
      class="tw-text-dark-green tw-cursor-pointer tw-flex tw-justify-between tw-items-center tw-shadow-custom tw-rounded-xl tw-p-3 tw-mb-3"
      @click="openDatesRoute()"
    >
      <img src="@/assets/svgs/yellow-calendar.svg" alt="Date" />
      <span class="tw-w-full tw-max-w-250 tw-text-left">{{
        trip && trip.startDate && trip.endDate
          ? getFormattedDateRange(trip.startDate, trip.endDate)
          : "Set the dates"
      }}</span>
      <img src="@/assets/svgs/chevron-right.svg" alt="Go" />
    </div>
    <div
      class="tw-text-dark-green tw-cursor-pointer tw-flex tw-justify-between tw-items-center tw-shadow-custom tw-rounded-xl tw-p-3 tw-mb-3"
      @click="
        goTo({
          name: 'DestinationsRoute',
          params: { tripId: trip.id }
        })
      "
    >
      <img src="@/assets/svgs/yellow-location.svg" alt="Destination" />
      <span class="tw-w-full tw-max-w-250 tw-text-left">{{
        trip && trip.destinations && trip.destinations.length > 0
          ? trip.destinations[0].name
          : "Choose the destination"
      }}</span>
      <img src="@/assets/svgs/chevron-right.svg" alt="Go" />
    </div>
    <div
      class="tw-text-dark-green tw-cursor-pointer tw-flex tw-justify-between tw-items-center tw-shadow-custom tw-rounded-xl tw-p-3"
      @click="openAccommodationsRoute()"
    >
      <img src="@/assets/svgs/yellow-address.svg" alt="Accommodation" />
      <span class="tw-w-full tw-max-w-250 tw-text-left"
        >{{ accomodationAddress }}<br /><span
          class="tw-underline"
          v-if="
            trip && finalizedAccommodation && finalizedAccommodation.address
          "
          ><a
            class="tw-text-dark-green"
            :href="'http://maps.google.com/?q=' + accomodationAddress"
            target="_blank"
            >Get Directions</a
          ></span
        ></span
      >
      <img
        src="@/assets/svgs/chevron-right.svg"
        alt="Go"
        @click.stop="goToPlacesToStay()"
      />
    </div>
    <h1
      class="tw-text-28 tw-text-left tw-font-figtree tw-font-semibold tw-my-5"
    >
      Itinerary
    </h1>
    <div id="chipsDivPlaceholder" style="height: 0"></div>
    <j-chip-group
      label=""
      :items="chips"
      :value="chipValue"
      @input="setActiveDate($event)"
      v-if="chips.length > 0"
      id="chipsDiv"
      :style="chipsStyle"
    />
    <div
      class="tw-flex tw-items-center tw-justify-center"
      v-if="!hasDestination || !hasDate"
    >
      <p class="tw-text-light-grey tw-py-4">
        Once both dates and destination for your trip are set, come back & start
        building your trip itinerary here.
      </p>
    </div>
    <div
      class="tw-flex tw-items-center tw-justify-center"
      v-else-if="
        hasDestination && hasDate && itinerary && itinerary.length === 0
      "
    >
      <p class="tw-text-light-grey tw-py-4">
        Start building your itinerary by adding events & things to do.
      </p>
    </div>
    <div
      class="itinerary-wrapper tw-mt-4"
      v-else-if="hasDestination && hasDate && itinerary && itinerary.length > 0"
    >
      <template v-for="(event, i) in itinerary">
        <p
          :key="event.id"
          :data-id="event.startDate.slice(0, 10)"
          class="tw-mt-0 tw-uppercase tw-text-left tw-font-semibold"
          v-if="
            i === 0 ||
            getChipDateText(event.startDate) !==
              getChipDateText(itinerary[i - 1].startDate)
          "
        >
          {{ getChipDateText(event.startDate) }}
        </p>
        <EventPost
          :event="event"
          :is-trip-owner="isTripOwner"
          :user-id="sessionUser.id"
          :uuid="event.uuid"
          :key="event.uuid"
          @delete="toggleDialog($event)"
        />
      </template>
      <!-- <EventPost
        v-for="event in nonSortableItinerary"
        :event="event"
        :is-trip-owner="isTripOwner"
        :user-id="sessionUser.id"
        :key="event.uuid"
        @delete="toggleDialog($event)"
      />
      <Draggable v-model="draggableItinerary">
        <EventPost
          v-for="event in draggableItinerary"
          :event="event"
          :key="event.uuid"
          :is-trip-owner="isTripOwner"
          :user-id="sessionUser.id"
          @delete="toggleDialog($event)"
        />
      </Draggable> -->
      <!-- <p
        class="tw-text-light-grey tw-py-4"
        v-if="
          nonSortableItinerary.length === 0 && draggableItinerary.length === 0
        "
      >
        No events today.
      </p> -->
    </div>
    <DeleteEventDialog
      :is-visible="isVisible"
      :event="eventToDelete"
      @cancel="toggleDialog"
      @close="toggleDialog"
    />
  </div>
</template>
<script>
import { EVENT_TYPES } from "@/enums/event-types.js";
import { FormattedDateRange } from "@/mixins/FormattedDateRange.js";
import EventPost from "@/components/itinerary/EventPost.vue";
import DeleteEventDialog from "@/components/itinerary/DeleteEventDialog.vue";
import { EVENTS } from "@/enums/events.js";
import { DateTime } from "luxon";
// import Draggable from "vuedraggable";
// the draggable element was used for reordering itinerary events
// if we were to add that again then uncomment this code and use the Draggable element in html again
// currently we are using full height scroll with automatic highlighting the active date
// Debounce function to limit the rate of execution

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

export default {
  name: "TripItineraryList",
  mixins: [FormattedDateRange],
  components: {
    EventPost,
    DeleteEventDialog
    // Draggable
  },
  data() {
    return {
      chipsStyle: {},
      eventToDelete: null,
      isVisible: false,
      showEventsMenu: false,
      sortableItinerary: [],
      nonSortableItinerary: [],
      restOfItinerary: [],
      itinerary: [],
      chips: [],
      activeDate: null,
      finalizedAccommodation: null,
      events: EVENTS,
      chipValue: null,
      dataLoaded: false
    };
  },
  computed: {
    draggableItinerary: {
      get() {
        return this.sortableItinerary;
      },
      set(value) {
        if (!this.isTripOwner) return;
        this.sortableItinerary = value;
        this.itinerary = [
          ...this.restOfItinerary,
          ...this.nonSortableItinerary,
          ...this.sortableItinerary
        ];
        let sortItinerary = this.itinerary
          .slice()
          .sort(
            (a, b) =>
              DateTime.fromISO(a.eventStartDate).diff(
                DateTime.fromISO(b.eventStartDate)
              ).milliseconds
          );
        //TODO patch itinerary with complete array?
        this.saveItinerary(sortItinerary);
      }
    },
    sessionUser() {
      return this.$store.state.auth.user;
    },
    trip() {
      return this.$store.state.trip.keyedById[this.$route.params.id];
    },
    isTripOwner() {
      return this.trip && this.trip.ownerId === this.sessionUser.id;
    },
    hasDestination() {
      return (
        this.trip && this.trip.destinations && this.trip.destinations.length > 0
      );
    },
    hasDate() {
      return this.trip.startDate && this.trip.endDate;
    },
    accomodationAddress() {
      if (!this.finalizedAccommodation) return "Plan your stay";

      if (this.finalizedAccommodation && !this.finalizedAccommodation.address) {
        if (this.isTripOwner) {
          return "Add address for your stay";
        }
        return "Your stay";
      }
      return this.finalizedAccommodation.address;
    },
    debouncedHandleChipsScroll() {
      return debounce(this.handleChipsScroll, 50);
    }
  },
  methods: {
    handleChipsScroll() {
      const track = document.getElementById("chipsDiv");
      const placeholder = document.getElementById("chipsDivPlaceholder");
      if (!track || !placeholder) return;

      const rect = track.getBoundingClientRect();
      let mLeft = window.innerWidth < 400 ? "-26px" : "-32px";

      if (rect.top <= 20) {
        // Set position to fixed
        this.chipsStyle = {
          position: "fixed",
          width: "100%",
          maxWidth: "460px",
          marginLeft: mLeft,
          top: "0",
          backgroundColor: "#E6FFA1",
          paddingTop: "10px",
          paddingBottom: "10px",
          zIndex: "10"
        };

        // Adjust the placeholder height to prevent layout shift
        placeholder.style.height = `${rect.height}px`;
      } else {
        // Set position to static
        this.chipsStyle = {};
        placeholder.style.height = "0";
      }

      if (window.pageYOffset <= 400) {
        this.chipsStyle = {};
        placeholder.style.height = "0";
      }
    },
    goToPlacesToStay() {
      sessionStorage.setItem("itinerary", true);
      this.$router.push({
        name: "AccommodationList"
      });
    },
    openSubmenuItem(routeName, type) {
      if (type) {
        this.$router.push({
          name: routeName,
          params: { value: type, activeDate: this.activeDate }
        });
      } else {
        this.$router.push({
          name: routeName,
          params: { activeDate: this.activeDate }
        });
      }
    },
    async saveItinerary(itinerary) {
      try {
        await this.$store.dispatch("itinerary/patch", [
          this.$route.params.id,
          itinerary
        ]);
      } catch (error) {
        console.error(error);
      }
    },
    async getItinerary() {
      const itineraryRes = await this.$store.dispatch("itinerary/find", {
        query: {
          tripId: this.$route.params.id
        }
      });
      if (itineraryRes.data) {
        this.itinerary = itineraryRes.data.filter((item) => {
          if (
            (item.eventType === EVENT_TYPES.ACCOMMODATION &&
              item.eventStartDate) ||
            (item.eventType !== EVENT_TYPES.ACCOMMODATION &&
              item.eventType !== EVENT_TYPES.DESTINATION)
          )
            return item;
        });
      }

      this.itinerary.sort((a, b) => {
        const getDateTime = (event) => {
          if (!event.eventStartDate) return null;
          const date = new Date(event.eventStartDate);
          if (event.eventStartTime) {
            const [hours, minutes, seconds] = event.eventStartTime.split(":");
            date.setHours(hours, minutes, seconds);
          } else {
            date.setHours(0, 0, 0);
          }
          return date;
        };

        const dateA = getDateTime(a);
        const dateB = getDateTime(b);

        if (dateA === null && dateB === null) return 0;
        if (dateA === null) return 1;
        if (dateB === null) return -1;

        return dateA - dateB;
      });

      if (this.$route.params.activityUUID) {
        this.findElement(this.$route.params.activityUUID);
      }
      if (
        this.itinerary.length > 0 ||
        (this.trip.startDate && this.trip.endDate)
      ) {
        this.createChips();
      }
    },
    createChips() {
      const sortedItinerary = this.itinerary
        .slice()
        .sort(
          (a, b) =>
            DateTime.fromISO(a.eventStartDate).diff(
              DateTime.fromISO(b.eventStartDate)
            ).milliseconds
        );

      const uniqueDatesSet = new Set();
      if (this.trip.startDate && this.trip.endDate) {
        let startDate = DateTime.fromISO(this.trip.startDate, {
          setZone: true
        }).endOf("day");
        let endDate = DateTime.fromISO(this.trip.endDate, {
          setZone: true
        }).endOf("day");
        for (
          let date = startDate;
          date <= endDate;
          date = date.plus({ days: 1 })
        ) {
          uniqueDatesSet.add(
            DateTime.fromISO(date, { setZone: true }).toISODate()
          );
        }
      }

      sortedItinerary.forEach((item) => {
        if (
          !uniqueDatesSet.has(
            DateTime.fromISO(item.eventStartDate, {
              setZone: true
            }).toISODate()
          )
        ) {
          uniqueDatesSet.add(
            DateTime.fromISO(item.eventStartDate, {
              setZone: true
            }).toISODate()
          );
        }
      });
      if (uniqueDatesSet.size > 0)
        this.chips = Array.from(uniqueDatesSet).sort((a, b) =>
          DateTime.fromISO(a, { setZone: true }) >
          DateTime.fromISO(b, { setZone: true })
            ? 1
            : -1
        );
      this.chipValue = this.chips[0];
      this.showItineraryEvents(this.chips[0]);

      //set date according to optional eventId sent through route params
      let sentEventUUID = this.$route.params.eventUuid;
      if (sentEventUUID) {
        let setDate = this.itinerary.filter(
          (event) => event.uuid === sentEventUUID
        )[0].eventStartDate;
        setDate = DateTime.fromISO(setDate, {
          setZone: true
        }).toISODate();
        this.chipValue = this.chips[this.chips.indexOf(setDate)];
        this.setActiveDate(setDate);
      }
    },
    showItineraryEvents(date) {
      this.restOfItinerary = this.itinerary.filter(
        (item) =>
          DateTime.fromISO(item.eventStartDate, {
            setZone: true
          }).toISODate() !==
          DateTime.fromISO(date, { setZone: true }).toISODate()
      );
      let filteredItinerary = this.itinerary.filter(
        (item) =>
          DateTime.fromISO(item.eventStartDate, {
            setZone: true
          }).toISODate() ===
          DateTime.fromISO(date, { setZone: true }).toISODate()
      );
      filteredItinerary.forEach((item) => {
        if (item.eventStartTime) {
          this.nonSortableItinerary.push(item);
        } else {
          this.sortableItinerary.push(item);
        }
      });
      this.nonSortableItinerary.sort(
        (a, b) =>
          DateTime.fromISO(a.eventStartTime).diff(
            DateTime.fromISO(b.eventStartTime)
          ).milliseconds
      );
      this.activeDate = date;
    },
    toggleDialog(event) {
      this.eventToDelete = event || null;
      this.isVisible = !this.isVisible;
      if (!event) {
        this.$store.commit(
          "itinerary/clearAll",
          this.$store.state["itinerary"]
        );
        this.getItinerary();
      }
    },
    activateEventsMenu() {
      this.showEventsMenu = true;
      document.querySelector("body").classList.add("fixed");
    },
    deactivateEventsMenu() {
      this.showEventsMenu = false;
      document.querySelector("body").classList.remove("fixed");
    },
    setActiveDate(d) {
      this.activeDate = d;
      this.resetArrays();
      this.showItineraryEvents(d);
      let element = document.querySelector(`[data-id="${d}"]`);
      if (!element) return;
      this.scrollToElement(element);
    },
    findElement(uuid) {
      this.$nextTick(() => {
        let element = document.querySelector(`[data-uuid="${uuid}"]`);
        if (!element) return;
        this.scrollToElement(element);
      });
    },
    scrollToElement(element) {
      let headerOffset = 100;
      let elementPosition = element.getBoundingClientRect().top;
      let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    },
    resetArrays() {
      this.sortableItinerary = [];
      this.nonSortableItinerary = [];
      this.restOfItinerary = [];
    },
    goTo(route) {
      sessionStorage.setItem("itinerary", true);
      this.$router.push(route);
    },
    openDatesRoute() {
      sessionStorage.setItem("itinerary", true);
      if (this.trip.startDate) {
        this.goTo({ name: "DatesListRoute" });
      } else {
        this.goTo({ name: "DatesCreateRoute" });
      }
    },
    openAccommodationsRoute() {
      sessionStorage.setItem("itinerary", true);
      if (!this.finalizedAccommodation) {
        this.goTo({ name: "AccommodationList" });
        return;
      }

      if (this.finalizedAccommodation && !this.finalizedAccommodation.address) {
        if (this.isTripOwner) {
          this.goTo({
            name: "AccommodationFinalize",
            params: { finalized: this.finalizedAccommodation }
          });
        } else {
          this.goTo({
            name: "AccommodationList"
          });
        }
      }
    },
    isInViewport(element) {
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    logElementsOnScroll() {
      const elementsWithDataId = document.querySelectorAll("[data-id]");

      elementsWithDataId.forEach((element) => {
        if (this.isInViewport(element)) {
          this.chipValue = element.getAttribute("data-id");
        }
      });
    }
  },
  async beforeMount() {
    sessionStorage.removeItem("itinerary");
    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: this.trip ? this.trip.title : "Trip",
      bgColor: "#E6FFA1",
      fontColor: "#203848",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      tripHubButton: true,
      tripHubButtonId: this.$route.params.id
    });

    await this.$store.dispatch("trip/get", [
      this.$route.params.id,
      {
        query: {
          include: "destination,users,survey,trip_invitation"
        }
      }
    ]);

    this.$store.commit("meta/setHeader", {
      ...this.$store.state.meta.header,
      pageTitle: this.trip.title
    });

    this.getItinerary();

    const finalizedRes = await this.$store.dispatch("accommodation/find", {
      query: {
        tripId: this.$route.params.id
      }
    });

    if (finalizedRes.data.length > 0)
      this.finalizedAccommodation = finalizedRes.data[0];

    this.dataLoaded = true;
  },
  mounted() {
    window.addEventListener("scroll", this.logElementsOnScroll);
    window.addEventListener("scroll", this.debouncedHandleChipsScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.logElementsOnScroll);
    window.removeEventListener("scroll", this.debouncedHandleChipsScroll);
  },
  destroyed() {
    this.$store.commit("itinerary/clearAll", this.$store.state["itinerary"]);
    document.querySelector("body").classList.remove("fixed");
  }
};
</script>
