<template>
  <div
    class="tw-relative tw-flex tw-items-start tw-shadow-custom tw-rounded-lg tw-py-6 tw-px-6 tw-mb-6"
    :data-uuid="uuid"
  >
    <img
      :src="getIcon()"
      alt="Test"
      class="tw-shrink-0 tw-w-8 tw-h-8 tw-object-cover"
    />
    <div class="tw-max-w-250 tw-ml-4">
      <p class="tw-text-xs tw-text-left tw-leading-3 tw-mb-0">
        {{ event.eventStartTime ? getTime(event.eventStartTime) : "" }}
        {{
          event.eventEndTime && !isAccommodationEvent(event)
            ? " - " + getTime(event.eventEndTime)
            : ""
        }}
      </p>
      <p
        class="tw-text-18 tw-font-medium tw-leading-5 tw-text-left tw-mt-2 tw-mb-0"
      >
        {{ getTitle(event) }}
      </p>
      <p
        class="tw-text-left tw-mt-1 tw-mb-0 tw-text-xs"
        v-if="event.nameOfPlace && event.nameOfPlace.length > 0"
      >
        {{ event.nameOfPlace }}
      </p>
      <p
        class="tw-text-left tw-mt-1 tw-mb-0 tw-text-xs tw-underline"
        v-if="event.address && event.address.length > 0"
      >
        <a
          class="tw-underline tw-text-dark-green"
          :href="'http://maps.google.com/?q=' + event.address"
          target="_blank"
          rel="noopener noreferrer"
          >{{ event.address }}</a
        >
      </p>
      <p
        class="tw-text-left tw-mt-1 tw-mb-0"
        v-if="
          (event.link && event.link.length > 0) ||
          (event.linkUrl && event.linkUrl.length > 0)
        "
      >
        <a
          class="tw-text-blue tw-text-xs tw-underline"
          :href="getHrefLink(event.link || event.linkUrl)"
          target="_blank"
          rel="noopener noreferrer"
          >View Details</a
        >
        <router-link
          class="tw-text-blue tw-text-xs tw-underline"
          :to="{
            name: 'AccommodationList'
          }"
          rel="noopener noreferrer"
          v-if="showViewDetails(event)"
          >View Details</router-link
        >
      </p>
      <p
        class="tw-text-light-grey tw-text-left tw-text-xs tw-mt-1 tw-mb-0"
        v-if="event.note && event.note.length > 0"
      >
        {{ event.note }}
      </p>
      <v-menu v-if="isTripOwner || isPostOwner">
        <template v-slot:activator="{ on }">
          <v-icon
            class="tw-ml-auto tw-self-start tw-text-warm-grey tw-absolute tw-right-5 tw-top-5"
            v-on="on"
            >mdi-dots-vertical</v-icon
          >
        </template>
        <v-list class="tw-text-xs">
          <v-list-item class="tw-cursor-pointer">
            <a
              href="#"
              class="tw-text-charcoal"
              @click.prevent="editEvent(event)"
            >
              <v-icon size="16" class="tw-mr-2" color="#333333"
                >mdi-pencil</v-icon
              >
              Edit</a
            >
          </v-list-item>
          <v-list-item
            class="tw-cursor-pointer"
            @click="$emit('delete', event)"
            v-if="canDelete"
          >
            <v-icon size="16" class="tw-mr-2" color="#333333">mdi-delete</v-icon
            >Delete
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>
<script>
import { EVENT_TYPES } from "@/enums/event-types.js";
import { UrlHelpers } from "@/mixins/UrlHelpers.js";

export default {
  mixins: [UrlHelpers],
  computed: {
    isPostOwner() {
      return this.event.authorId === this.userId;
    },
    canDelete() {
      return (
        this.event.eventType === EVENT_TYPES.ITINERARY_EVENT ||
        this.event.eventType === EVENT_TYPES.ACTIVITY
      );
    }
  },
  props: {
    event: {
      type: Object
    },
    owner: {
      type: Boolean,
      default: false
    },
    uuid: {
      type: String
    },
    userId: {
      type: Number
    },
    isTripOwner: {
      type: Boolean
    }
  },
  methods: {
    isAccommodationEvent(event) {
      return event.eventType === EVENT_TYPES.ACCOMMODATION;
    },
    showViewDetails(event) {
      return (
        event.eventType &&
        event.eventType === EVENT_TYPES.ACCOMMODATION &&
        ((!event.link && !event.linkUrl) ||
          (event.link && event.link.length === 0) ||
          (event.linkUrl && event.linkUrl.length === 0))
      );
    },
    getTitle(event) {
      if (event.eventType === EVENT_TYPES.ACCOMMODATION)
        return "CHECK-IN TO YOUR STAY";
      if (event.description && event.description.length > 0)
        return event.description;
      if (event.name && event.name.length > 0) return event.name;
      return event.eventType;
    },
    editEvent(event) {
      if (this.event.eventType === EVENT_TYPES.ITINERARY_EVENT) {
        this.$router.push({
          name: "TripItineraryEdit",
          params: { id: this.event.tripId, item: event.preservedId }
        });
      }

      if (event.eventType === EVENT_TYPES.ACTIVITY) {
        this.$router.push({
          name: "EditActivityFromItineraryRoute",
          params: { id: this.event.tripId, item: event.preservedId }
        });
      }

      if (event.eventType === EVENT_TYPES.ACCOMMODATION) {
        sessionStorage.setItem("itinerary", true);
        this.$router.push({
          name: "AccommodationFinalize",
          // Make sure that the accommodation edit form is being passed the preservedId and not the indexed id returned from
          // the itinerary service so it does not attempt to update an incorrect record in the DB
          params: {
            finalized: {
              ...this.event,
              id: this.event.preservedId
            }
          }
        });
      }

      if (event.eventType === EVENT_TYPES.DESTINATION) {
        this.$router.push({
          name: "DestinationsRoute",
          params: { tripId: this.event.tripId }
        });
      }
    },
    getTime(time) {
      if (parseInt(time.split(":")[0]) <= 12) {
        return `${this.mapNumbers(time.slice(0, -3))} `;
      }
      return `${this.mapNumbers(time.slice(0, -3))}`;
    },
    mapNumbers(timeString) {
      // Split the time string into hours and minutes
      const [hours, minutes] = timeString.split(":").map(Number);

      // Handle special cases for midnight
      if (hours === 0) {
        return "12:" + minutes.toString().padStart(2, "0") + " AM";
      }

      if (hours === 12) {
        return "12:" + minutes.toString().padStart(2, "0") + " PM";
      }

      // Map hours according to the specified pattern
      if (hours >= 13 && hours <= 23) {
        return (
          (hours - 12).toString().padStart(2, "0") +
          ":" +
          minutes.toString().padStart(2, "0") +
          " PM"
        );
      } else {
        return timeString + " AM";
      }
    },
    getIcon() {
      let eventType = this.event.eventType;
      if (
        eventType === EVENT_TYPES.ITINERARY_EVENT ||
        eventType === EVENT_TYPES.ACTIVITY
      )
        return require(`@/assets/svgs/itinerary/${this.event.type}.svg`);

      if (eventType === EVENT_TYPES.ACCOMMODATION)
        return require(`@/assets/svgs/itinerary/STAY.svg`);

      return require(`@/assets/svgs/itinerary/OTHER.svg`);
    }
  }
};
</script>
