export const UrlHelpers = {
  methods: {
    getHrefLink(link) {
      if (link.includes("://")) {
        let splittedLink = link.split("://");
        link = `https://${splittedLink[1]}`;
      } else {
        link = `https://${link}`;
      }
      return link;
    },
  },
};
